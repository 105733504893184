import React, { useEffect, useState } from 'react';
import Header from './Header'
import usePageTracking from './Analytics'
import Assurers from './Assurers'
import Cookies from 'universal-cookie';



function Signup(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const userUuid = queryParams.get('userid');

  const cookies = new Cookies();

  function ifCookie(k) {
    const v = cookies.get(k);
    return (v && v.length) ? v : '';
  }

  const [phone, setPhone] = useState(ifCookie('phone'));
  const [zip, setZip] = useState(ifCookie('zip'));
  const [firstName, setFirstName] = useState(ifCookie('firstName'));
  const [lastName, setLastName] = useState(ifCookie('lastName'));
  const [email, setEmail] = useState(ifCookie('email'));
  const [aOrB, setAOrB] = useState('');


  const [errors, setErrors] = useState({});

  const [canSubmit, setCanSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  const isRefer = props.refer === true;
  const isDirect = props.direct === true; // /enrollment
  const inApp = props.inApp === true; // /app-enrollment


  usePageTracking();

  useEffect(function() {
    setAOrB('A');
  }, []);


  function handleLabel(e) {
    if (e.target.value !== '') {
      e.target.classList.add('not_empty');
    } else {
      e.target.classList.remove('not_empty');
    }
  }

  /* Puts digits in North American phone format */
  function formatAmericanNumber(value, previousValue) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  }


  function verifyInputs(key, value) {
    const newErrors = {
      'firstName': !((key === 'firstName' ? value : firstName).length > 0),
      'lastName': !((key === 'lastName' ? value : lastName).length > 0),
      'email': !(/^[^@]+@[^@]+\.[^@]+$/.test((key === 'email') ? value : email)),
      'zip': !((key === 'zip' ? value : zip).length === 5 && !isNaN((key === 'zip' ? value : zip)) && !isNaN(parseFloat((key === 'zip' ? value : zip)))),
      'phone': (aOrB === 'B') ? false : ((key === 'phone' ? formatAmericanNumber(value) : phone).length !== 14),
    };


    const allErrors = Object.keys(newErrors).filter(k => { return (newErrors[k] === true) });
    if (key) {
      const f = allErrors.filter(k => { return (k === key) });
      let t = {};
      t[key] = f.length !== 0;
      setErrors(t);
    } else {
      setErrors(newErrors);
    }
    const res = (allErrors.length === 0);
    setCanSubmit(res);
    return res;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (verifyInputs()) {
      let expiry = new Date();
      expiry.setHours(expiry.getHours() + 1);

      cookies.set('firstName', firstName, { path: '/', expires: expiry })
      cookies.set('lastName', lastName, { path: '/', expires: expiry })
      cookies.set('email', email, { path: '/', expires: expiry })
      if (aOrB === 'A') {
        cookies.set('phone', phone, { path: '/', expires: expiry })
      }
      cookies.set('zip', zip, { path: '/', expires: expiry })
      setSubmitted(true);
    }
  }


  function handlePhoneChange(e) {
    setPhone(prevState => (formatAmericanNumber(e.target.value, prevState) ));
  }


  const utmMedium = queryParams.get('utm_medium');
  const canSeeForm = (isDirect || (isRefer && (utmMedium === 'refer' || utmMedium === 'referral')) || userUuid && userUuid?.length > 0);


  if (submitted) {
    return <Assurers aOrB={aOrB} firstName={firstName} lastName={lastName} zip={zip}
      email={email} phone={phone} inApp={inApp} isDirect={isDirect}
      isRefer={isRefer} back={ e => setSubmitted(false) } />
  }


  return (
    <>
      <Header />
      <main>
        <section>
          { (!(isRefer || isDirect)) && <h3>Sign up for SteadyDrive, where good drivers can save hundreds per year on auto insurance.</h3> }
          { ((isRefer && !isDirect) || (!isRefer && isDirect)) && <>
              <h3>Sign up for SteadyDrive, where good drivers can save hundreds per year on auto insurance.</h3>
              { isRefer &&
              <a className='learn_more' rel='noreferrer' target='_blank' href='https://steadydrive.com'>Learn more about SteadyDrive</a>
              }
            </>
          }
          { (canSeeForm) &&
            <form onSubmit={handleSubmit}>
              <div className={'flex-groups' + ((errors.firstName || errors.lastName) ? ' invalid' : '')}>
                <div className='form-group'>
                  <input type='text' placeholder='First name' name='gname' value={firstName} id='gname' onKeyUp={(e) => handleLabel(e)} onChange={ e => { setFirstName(e.target.value); verifyInputs('firstName', e.target.value) }} autoFocus='yes' required />
                  <span>First name</span>
                </div>
                <div className='form-group'>
                  <input type='text' placeholder='Last name' name='name' value={lastName} id='name' onKeyUp={(e) => handleLabel(e)} onChange={ e => { setLastName(e.target.value); verifyInputs('lastName', e.target.value); }} required />
                  <span>Last name</span>
                </div>
              </div>
              { (errors.firstName || errors.lastName) && 
                <div className='error-group'>
                  Please enter a valid name.
                </div>
              }
              <div className={ 'form-group' + (errors.email ? ' invalid' : '')}>
                <input type='email' placeholder='Email address' value={email} name='email' id='email' onKeyUp={(e) => handleLabel(e)} onChange={ e => { setEmail(e.target.value); verifyInputs('email', e.target.value); }} required />
                <span>Email address</span>
              </div>
              { errors.email && 
                <div className='error-group'>
                  Please enter a valid email.
                </div>
              }

            { (aOrB === 'A') &&
                <>
                  <div className={ 'form-group' + (errors.phone ? ' invalid' : '')}>
                    <input type='tel' placeholder='Mobile number' name='phone' id='phone' onKeyUp={(e) => handleLabel(e) } onChange={ e => { handlePhoneChange(e); verifyInputs('phone', e.target.value); }} value={phone || ''} required />
                    <span>Mobile number</span>
                  </div>
                  { errors.phone && 
                    <div className='error-group'>
                      Please enter a valid 10-digit phone number.
                    </div>
                  }
              </>
            }
              <div className={ 'form-group' + (errors.zip ? ' invalid' : '')}>
                <input type='number' placeholder='Home ZIP Code' name='zip' value={zip} id='zip' onKeyUp={(e) => handleLabel(e)} onChange={ e => { setZip(e.target.value); verifyInputs('zip', e.target.value); }} required />
                <span>Home ZIP Code</span>
              </div>
              { errors.zip && 
                <div className='error-group'>
                  Please enter a valid 5-digit ZIP code.
                </div>
              }
              <div className='form-group'>
                <input type='submit' className={ (!canSubmit ? 'disabled' : '') } value='Continue' disabled={ !canSubmit ? true : ''} />
              </div>
            </form>
          }
          { !canSeeForm && 
              <p>Neither a user ID nor a referral have been supplied. To register, please return to the Steady app and follow again the link to SteadyDrive.</p>
          }
        </section>
      </main>
    </>
  );

}

export default Signup;
