import React, { useEffect, useState } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';
import Header from './Header'
import ios from '../img/ios.png';
import android from '../img/android.png';
import getMobileOperatingSystem from './GetMobileOs';
import ReactPixel from 'react-facebook-pixel';
//import TiktokPixel from 'tiktok-pixel';
import RedditPixel from 'react-reddit-pixel';
import usePageTracking from './Analytics';
import TagManager from 'react-gtm-module';


function Download() {
  usePageTracking();

  const location = useLocation();
  const mos = getMobileOperatingSystem();
  const inApp = location?.state?.inApp === true; // /app-enrollment
  const isFrame = location?.state?.isFrame === true;
  const driver = location?.state?.driver;
  const email = location?.state?.email;
  const phone = location?.state?.phone;
  const ire = window.ire;


  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('564814192124959', {}, options);


  //const ttOptions = {
  //  debug: false, // enable logs
  //};
  //TiktokPixel.init('CHCJQBJC77U7A82IRP0G', {}, ttOptions);

  const redditOptions = {
    debug: true, // enable logs
    optOut: false,
    useDecimalCurrencyValues: true,
    'email': email,
    'phone': phone,
  };
  RedditPixel.init('t2_tb70ap77', redditOptions);


  
  async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  function trackConversion(hex) {
    // Facebook Pixel 
    ReactPixel.track('CompleteRegistration');

    // TikTok Pixel 
    //const ttParams = {
    //  'email': email,
    //  'phone': phone
    //};
    //TiktokPixel.track('Download', ttParams);

    // Reddit Pixel
    RedditPixel.track('SignUp');

    // Google Tag Manager
    TagManager.dataLayer({
      dataLayer: {
        event: 'trackConversion',
        pagePath: location.pathname + location.search,
        pageTitle: 'SteadyDrive Download',
      },
    });

    // Impact
    ire('trackConversion', 34116, {
      orderId: driver?.driver_uuid,
      customerId: driver?.driver_id,
      customerEmail: hex,
    },
      {
        verifySiteDefinitionMatch:true
      }
    );

  }


  useEffect(function() {
    digestMessage(email).then((hex) => {
      trackConversion(hex);
    });

  }, []);

  if (!driver?.login_url) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  if (inApp) {
    window.location.replace(driver.login_url); 
  }


  return (
    <>
      <Header large='true' />
      <main>
        <section className='centre extra_h_pad'>
            <h3 className='half_margin'>Confirmed!</h3>
            { (mos > 0) ?
              <>
                <p className='sm'>Tap the link below to download the SteadyDrive app.</p>
                <p><a className='app_store_link' target='_blank' href={driver.login_url}><img src={(mos === 2) ? android : ios} alt='Download the SteadyDrive app' /></a></p>
              </> : <>
                <p className='sm'>Your enrollment in SteadyDrive is complete! Scan the code below to get started now.  We’ll also send you an email and SMS message including this personalized download link.</p>
                <img src={ 'https://api.qrserver.com/v1/create-qr-code/?size=200x200&format=svg&data=' + encodeURIComponent(driver.login_url) } />
              </>
          }
        </section>
      </main>
    </>
  );

}

export default Download;
