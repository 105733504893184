import React from 'react';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return -1;
  }

  if (/android/i.test(userAgent)) {
    return 2;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 1;
  }

  return -2;
}

export default getMobileOperatingSystem;
