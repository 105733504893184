import React from 'react';
import Title from './Title';
import { Link } from 'react-router-dom';

function NoMatch() {
  return (
      <main>
        <Title>page not found</Title>
        <section>
          <h2>The page for which you are looking was not found. It might be in development.</h2>
        </section>
      </main>
  );
}

export default NoMatch;
