import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'

const usePageTracking = () => {

  const tagManagerArgs = {
    gtmId: 'GTM-P59F8G6'
  };

  TagManager.initialize(tagManagerArgs);


  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-VRL1HH2E8P');
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

export default usePageTracking;
