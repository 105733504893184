import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Signup from './components/Signup'
import Assurers from './components/Assurers'
import Download from './components/Download'
import NoMatch from './components/NoMatch'

import { HelmetProvider } from 'react-helmet-async';


import './css/style.css';


export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div id='wrap'>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={ <Signup showReturnToSteadyOnUnavailable={true} /> } />
            <Route path='/refer-enrollment' element={ <Signup refer={true} /> } />
            <Route path='/app-enrollment' element={ <Signup direct={true} inApp={true} /> } />
            <Route path='/enrollment' element={ <Signup direct={true} /> } />
            <Route path='/insurers' element={ <Assurers direct={true} frame={true} /> } />
            <Route path='/assurers' element={ <Assurers direct={true} frame={true} /> } />
            <Route path='/download' element={ <Download /> } />
            <Route path='*' element={ <NoMatch /> } />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
