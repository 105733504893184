import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import usePageTracking from './Analytics'
import NotAvailable from './NotAvailable'
import getMobileOperatingSystem from './GetMobileOs'
import Cookies from 'universal-cookie';
import Header from './Header'

import Back from '../img/back.svg'
import Geico from '../img/geico.svg'
import Nationwide from '../img/nationwide.svg'
import Statefarm from '../img/statefarm.svg'
import Allstate from '../img/allstate.svg'
import Progressive from '../img/progressive.svg'

const API_BASE = process.env.REACT_APP_IS_DEV === 'yes' ? process.env.REACT_APP_API_BASE_DEV : process.env.REACT_APP_API_BASE_PROD;
const ZIP_API =  API_BASE + 'state/';
const DRIVER_API = API_BASE + 'driver';
const STATE_API_KEY = process.env.REACT_APP_STATE_API_KEY;

function Assurers(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const userUuid = queryParams.get('userid');


  const [isLoading, setIsLoading] = useState(false);
  const [driverResponse, setDriverResponse] = useState({});

  const [consent, setConsent] = useState(false);
  const [assurer, setAssurer] = useState('');

  usePageTracking();
  const cookies = new Cookies();

  function paramsToObject(p) {
    const res = {}
    for (const [key, value] of p) {
      res[key] = (value === 'true') ? true : ((value === 'false') ? false : value);
    }
    return res;
  }


  const { aOrB, firstName, lastName, email, phone,
    zip, inApp, isDirect, isRefer, isFrame, back } = (queryParams.get('isFrame') === 'true') ? paramsToObject(queryParams) : props;

  function getUtm(t) {
    return queryParams.get(t) ? queryParams.get(t) : 'N/A';
  }

  async function makeDriver(state) {
    const mos = getMobileOperatingSystem();

    let postBody = {
      'first_name': firstName,
      'last_name': lastName,
      'email': email,
      'state_code': state,
      'country_code': 'USA',
      'zip_code': zip,
      'enrollment_user_agent': (mos === 1) ? 'ios' : ((mos === 2) ? 'android' : 'other'),
      'insurer': assurer,
      'utm_medium': getUtm('utm_medium'),
      'utm_source': getUtm('utm_source'),
      'utm_campaign': getUtm('utm_campaign'),
      'utm_content': getUtm('utm_content'),
      'utm_term': getUtm('utm_term'),
      'referrer_page': getUtm('referrer_page'),
      'source_env': 'v1',
    };


    // case A or Affiliate form
    if (!aOrB || aOrB === 'A') {
      postBody['mobile_number'] = phone;
    }

    if (!isRefer && !inApp) {
      postBody['driver_uuid'] = userUuid;
    }

    fetch(DRIVER_API, {
      method: 'POST',
      headers: {},
      body: JSON.stringify(postBody)
    }).then((response) => {
      return Promise.all([Promise.resolve(response.status), response.json()]);
    }).then((_data) => {
      const status = _data[0];
      const data = _data[1].data;
      setIsLoading(false);
      if (status === 409) {
        // already registerd
        alert('You are already registered.');
      } else if (data?.message?.name === 'Error') {
        alert('There were problems submitting your information. Please contact the development group.');
      } else if (status === 400) {
        alert('There were problems submitting your information. Please contact the development group.');
      } else if (status === 404) {
        setDriverResponse({ covered: false, driver_id: '' });
      } else {
        let dd = data;
        dd.covered = true;
        setDriverResponse(dd);
      }

    });
  }

  function verifyInputs() {
    return assurer && assurer.length > 0 && consent;

  }

  function goBack() {
    window.history.go(-1)
  }


  async function handleSubmit(e) {
    e.preventDefault();
    if (verifyInputs()) {
      setIsLoading(true);
      fetch(ZIP_API + zip, {
        method: 'GET',
        headers: {},
      }).then((response) =>
        response.json().then(data => ({
          data: data,
          status: response.status
        })
      ).then((r) => {
        makeDriver(r.data.state);
      }));
    }
  }

  if (driverResponse && driverResponse.driver_id !== undefined) {
    return (driverResponse?.covered === false) ? <NotAvailable showReturn={ props.showReturnToSteadyOnUnavailable } /> : <Navigate to='/download' state={{ inApp: inApp, driver: driverResponse, email: email, phone: phone }} />;
  }


  return (
    <>
      { !inApp && window.innerWidth > 450 && <Header /> }
      <main>
        <section>
          <div className='header_flex'>
            <div onClick={isFrame ? goBack : back } id='back' aria-label='return to the previous form'
                style={{backgroundImage: 'url(' + Back + ')' }}></div>
            <h3>Who&rsquo;s your current auto insurance&nbsp;carrier?</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div id='carriers' className={ (assurer && assurer.length > 0) ? 'something_selected' : '' }>
              <div id='geico' onClick={ e => setAssurer('Geico') } className={ assurer === 'Geico' ? 'selected' : '' }>
                <div aria-label='Geico' style={{ backgroundImage: 'url(' + Geico + ')' }}></div>
              </div>
              <div id='nationwide' onClick={ e => setAssurer('Nationwide') } className={ assurer === 'Nationwide' ? 'selected' : '' }>
                <div aria-label='Nationwide' style={{ backgroundImage: 'url(' + Nationwide + ')' }}></div>
              </div>
              <div id='allstate' onClick={ e => setAssurer('Allstate') } className={ assurer === 'Allstate' ? 'selected' : '' }>
                <div aria-label='Allstate' style={{ backgroundImage: 'url(' + Allstate + ')' }}></div>
              </div>
              <div id='statefarm' onClick={ e => setAssurer('State Farm') } className={ assurer === 'State Farm' ? 'selected' : '' }>
                <div aria-label='Statefarm' style={{ backgroundImage: 'url(' + Statefarm + ')' }}></div>
              </div>
              <div id='progressive' onClick={ e => setAssurer('Progressive') } className={ assurer === 'Progressive' ? 'selected' : '' }>
                <div aria-label='Progressive' style={{ backgroundImage: 'url(' + Progressive + ')' }}></div>
              </div>
            </div>
            <div id='other_options'>
              <div onClick={ e => setAssurer('Other') } className={ assurer === 'Other' ? 'selected' : '' }>Other</div>
              <div onClick={ e => setAssurer('None') } className={ assurer === 'None' ? 'selected' : '' }>I don&rsquo;t have insurance</div>
            </div>
    
            <div id='consent_wrap' className='form-group'>
              <div className='flex-label'>
                <input type='checkbox' name='consent' id='consent' onChange={ e => setConsent(e.target.checked) } required />
                <label htmlFor='consent'>I agree to the <a href='https://www.steadydrive.com/terms-of-service' target='_blank'>SteadyDrive Terms of Service</a> and <a href='https://www.steadydrive.com/privacy-policy' target='_blank'>Privacy Policy</a> and to receive text messages to the phone number provided above in connection with my use of the app.</label>
              </div>
            </div>
            { consent }
            <div className='form-group'>
              <input type='submit' className={ ((!assurer || !consent || assurer.length === 0 || isLoading) ? 'disabled' : '') + (' ' + (isFrame ? 'frame' : '')) } value={ isLoading ? 'Submitting …' : 'Sign up and save' } disabled={ (!assurer || !consent || assurer.length === 0 || isLoading) ? true : ''} />
            </div>
          </form>
        </section>
      </main>
    </>
  );
}

export default Assurers;
