import React, { useEffect, useState } from 'react';
import logo from '../img/logo.svg';
import logol from '../img/logo_light_bg.svg';


function Header(props) {
  return (
   <header className={props.large ? 'large' : ''} style={{ backgroundImage: 'url(' + (props.large ? logol : logo)  + ')'}}>
    </header>
  );

}

export default Header;
