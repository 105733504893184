import React from 'react';
import { Helmet } from 'react-helmet-async';

function Title(props) {
  return (
    <Helmet> 
      <title>{ props.children } | Sign Up For Car Insurance Discounts | SteadyDrive</title>
      <meta name='description' content='{ props.description ? (props.description : "SteadyDrive") }' />
    </Helmet>
  );
};

export default Title

