import React, { useEffect, useState } from 'react';
import Title from './Title';
import Header from './Header'

function NotAvailable(props) {

  return (
    <>
      <Header large='true' />
      <main>
        <Title>unavailable</Title>
        <section className='extra_h_pad'>
          <h3 className='half_margin'>Sorry, SteadyDrive is not yet available in your area.</h3>
          <p>We’re working hard to expand our services. We’ve put you on our waitlist and will let you know when your area is available.</p>
        </section>
        { props.showReturn && 
          <section>
            <button>Return to Steady</button>
          </section>
        }
      </main>
    </>
  );

}

export default NotAvailable;
